import { useCallback, useMemo } from "react";
import _ from "lodash";

import { useStorage } from "./useStorage";
import translations from "translations";

const useTranslations = () => {
  const { localStorageData } = useStorage();

  const rtlLanguages = useMemo(() => ["he", "ar"], []);

  const appLanguage = useMemo(
    () => localStorageData?.appLanguage || "he",
    [localStorageData?.appLanguage]
  );

  const direction = useMemo(
    () =>
      localStorageData?.appLanguage &&
      rtlLanguages.includes(localStorageData?.appLanguage)
        ? "rtl"
        : "ltr",
    [localStorageData?.appLanguage, rtlLanguages]
  );

  const oppositeDirection = useMemo(
    () =>
      localStorageData?.appLanguage &&
      rtlLanguages.includes(localStorageData?.appLanguage)
        ? "ltr"
        : "rtl",
    [localStorageData?.appLanguage, rtlLanguages]
  );

  const language = useMemo(() => appLanguage, [appLanguage]);

  const rcTranslate = useCallback(
    (stringId, args = []) => {
      let value = translations[language]
        ? _.at(translations[language], stringId)
        : stringId;
      if (!value[0]) {
        return stringId;
      }
      if (value[0].length > 0) {
        if (args && Array.isArray(args) && args.length > 0) {
          value = replacePlaceholders(value[0], args);
          return value;
        }
        return value[0];
      }
      return value[0];
    },
    [language]
  );

  /**
   * @param text
   * @param placeholders
   * @returns string
   */
  const replacePlaceholders = (text, placeholders) => {
    if (!text) {
      return "";
    }

    placeholders.forEach((placeholder) => {
      if (placeholder) {
        text = text.replace(`{${placeholder.label}}`, placeholder.value);
      }
    });

    return text;
  };

  return { rcTranslate, direction, oppositeDirection };
};

export default useTranslations;
