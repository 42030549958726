import { useCallback, useState } from "react";

import * as api from "api";

export const useApi = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { client, get, getWithAuth, post, postWithAuth } = api;

  const executeApi = useCallback(
    async (args) => {
      try {
        setError(null);
        setLoading(true);

        const res = await client.request(args);
        const { data } = res;

        if (!data.isSucceeded) {
          throw Error(JSON.stringify(res));
        }

        setLoading(false);
        return data;
      } catch (err) {

        const statusCode = err?.response?.status;
        const errors = Array.isArray(err) ? err : err?.response?.data?.errors;

        // const parsedErrors = JSON.parse(err?.message);
        // const statusCode = parsedErrors?.status;
        // const errors = parsedErrors?.data?.errors;

        if (statusCode === 401) {
          setLoading(false);
          return;
        }
        if (statusCode === 400) {
          return "שגיאה כללית";
        }

        setError(errors);
        setLoading(false);

        return err;
        //  return errors;
      }
    },
    [client]
  );

  return {
    executeApi,
    get,
    getWithAuth,
    post,
    postWithAuth,
    error,
    loading,
  };
};
