import React from "react";
import { TextField } from "@mui/material";

import useTranslations from "hooks/useTranslations";
import { onlyDigits, onlyLetters } from "utils/validation";

import "./Input.scss";

const Input = ({ validation = "", inputProps = {}, ...props }) => {
  const { rcTranslate } = useTranslations();

  const handleChange = (e) => {
    let value = e.target.value;

    // For validation === "number" - insert only digits to value
    if (validation === "number") {
      const hasOnlyDigits = onlyDigits(value);

      if (!hasOnlyDigits) {
        value = value.replace(/\D/g, "");
      }
    }

    // For validation === "letters" - insert only letters to value
    if (validation === "letters") {
      const hasOnlyLetters = onlyLetters(value);

      if (!hasOnlyLetters) {
        value = value.replace(/[^a-z A-Zא-ת]/gi, "");
      }
    }

    e = { ...e, target: { ...e.target, value } };
    props.onChange && props.onChange(e);
  };

  return (
    <TextField
      id={props.id}
      className={`${props.className ?? ""} ${
        props.value ? "inputComponent-filled" : ""
      } ${props.error ? "inputComponent-error" : ""} inputComponent`}
      type={props.type}
      name={props.id}
      autoComplete="off"
      multiline={props.multiline ?? false}
      rows={props.multiline ? 4 : 1}
      error={!!props.error}
      helperText={props.error ? rcTranslate(props.error) : " "}
      InputLabelProps={{ required: false, shrink: true }}
      label={props.label}
      placeholder={props.placeholder}
      variant={"outlined"}
      value={props.value}
      onChange={handleChange}
      onBlur={props.onBlur}
      required={!!props.required}
      inputProps={inputProps}
      autoFocus={props?.autoFocus}
      disabled={props?.disabled ?? false}
    />
  );
};

export default Input;
