import useTranslations from "hooks/useTranslations";
import React from "react";

import "./PageTitle.scss";

const PageTitle = ({ title = "", style = {}, className = "" }) => {
  const { rcTranslate } = useTranslations();

  return (
    <div className={`pageTitle ${className}`} style={style}>
      {rcTranslate(title)}
    </div>
  );
};

export default PageTitle;
