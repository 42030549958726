import Header from "components/Header/Header";
import OTP from "pages/Migration/OTP";
import StudentDetailsLink from "pages/Migration/StudentDetailsLink";
import Voucher from "pages/Migration/Voucher";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <Router basename="/onboarding">
      <App />
    </Router> */}

    <Router>
      <Header showGoBackButton={false} />
      <Switch>
        <Route exact path={"/voucher-number"}>
          <Voucher />
        </Route>
        <Route path={"/otp"}>
          <OTP />
        </Route>
        <Route path={"/student-details"}>
          <StudentDetailsLink />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>
);
