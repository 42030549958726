const translations = {
  landingPage: {
    title: "הזמנת לוקר",
    question: "איך תבחרו את הלוקר המושלם לילד שלכם?",
    additionalText:
      "מלאו את הפרטים וענו על השאלות ויחד נמצא את הלוקר המתאים ביותר עבור התלמיד!",
    buttonText: "בואו נתחיל!",
  },
  chooseGrade: {
    title: "באיזה כיתה לומד התלמיד/ה?",
    gradeAndClass: "שכבה וכיתה",
    chooseGradeAndClass: "בחירת שכבה וכיתה",
  },
  chooseCompound: {
    title: "איזה מתחם אתם מחפשים?",
    chooseCompound: "בחירת מתחם",
    typeCompoundName: "הקלידו את שם המתחם או בחרו מהרשימה",
  },
  chooseLockerNumber: {
    title: "מה מספר הלוקר שאתם מחפשים?",
    lockerNumber: "מספר לוקר",
    typeLockerNumber: "הקלידו את מספר הלוקר",
  },
  chooseLocker: {
    title: "בחירת לוקר לפי",
    byClass: "כיתה",
    byCompound: "מתחם",
    byLockerNumber: "מספר לוקר",
    autoComplete: {
      label: "מתחם",
      placeholder: "בחירת מתחם",
    },
    seeCompoundsMap: "צפייה במפת מתחמים",
  },
  studentDetails: {
    title: "פרטי תלמיד",
    waitingLine: " - רשימת המתנה",
    form: {
      fullName: {
        label: "שם התלמיד",
        placeholder: "מלאו את השם המלא",
      },
      phoneNumber: {
        label: "מספר טלפון נייד",
        placeholder: "הכניסו מספר נייד כדי שנוכל לשלוח לתלמיד קישור ללוקר שלו",
      },
      identificationNumber: {
        label: "מספר תעודת זהות",
        placeholder: "הקלידו מס׳ תעודת זהות כולל ספרת ביקורת",
      },
      grade: {
        label: "שכבה וכיתה",
        placeholder: "בחירת שכבה וכיתה",
      },
    },
  },
  parentDetails: {
    title: "פרטי המזמין (ההורה)",
    waitingLine: " - רשימת המתנה",
    form: {
      fullName: {
        label: "שם מלא",
        placeholder: "שם מלא של ההורה המזמין",
      },
      phoneNumber: {
        label: "מספר נייד",
        placeholder: "מספר נייד של ההורה המזמין",
      },
      email: {
        label: "דוא״ל",
        subLabel: "(לכאן נשלח את החשבונית)",
        placeholder: "דואר אלקטרוני של ההורה המזמין",
      },
      confirmRules: {
        label: "אני מאשר/ת את",
        link: "התקנון",
      },
      mailSubscription: {
        label: "אני רוצה להיכנס לרשימת הדיור",
      },
    },
  },
  orderConfirmation: {
    title: "אישור הזמנה",
    waitingListTitle: "אישור הזמנה - רשימת המתנה",
    studentDetails: {
      title: "פרטי התלמיד",
      fullName: "שם מלא",
      school: "בית הספר",
      identificationNumber: "תעודת זהות",
      phoneNumber: "טלפון",
      lockerNumber: "לוקר",
      compoundName: "מתחם",
      compoundNameWaitingList: "ברשימת המתנה למתחם",
      changeLocker: "שינוי בחירה",
    },
    parentDetails: {
      title: "פרטי המזמין",
      fullName: "שם מלא",
      phoneNumber: "טלפון",
      email: "דוא״ל",
    },
    paymentDetails: {
      title: "סה״כ לתשלום",
      period: "תקופה",
    },
    editDetails: "עריכת פרטים",
    button: "המשך לתשלום",
  },
  lockers: {
    label: "ארון מס׳",
    seeCompoundImage: "צפייה בתמונת מתחם",
  },
  payment: {
    title: "פרטי תשלום",
    totalPayment: "סך הכל לתשלום"
  },
  button: {
    continue: "המשך",
    choose: "בחירה",
  },
  form: {
    errors: {
      onlyNumbers: "יש להזין מספרים בלבד",
      required: "שדה חובה",
      identificationNumber: "מספר תעודת הזהות אינו תקין",
      phoneNumber: "מספר הטלפון הנייד אינו תקין",
      email: "הדוא״ל שהוזן אינו תקין",
    },
  },
  migration: {
    voucher: {
      title: "השלמת פרטי רישום",
      subTitle:" אנא הזינו את מספר השובר שנשלח אליכם ב SMS ",
      voucherNumber: {
        label: "מספר שובר",
        placeholder: "הזינו את מספר השובר",
      },
    },
    otp: {
      title: " הזינו את הקוד שקיבלתם בSMS  ",
      timer:"קוד האימות ישלח תוך {timer} שניות",
      sendAgain:"שלח שוב"
    },
    studentDetails: {
      title: "פרטי תלמיד",
      subTitle:
          "אנא השלימו את הפרטים על מנת שהתלמיד/ה יוכלו להוריד את האפליקציה ולפתוח את הלוקר בקלות" ,
      firstName: {
        label: "שם פרטי",
        placeholder: "מלאו שם פרטי",
      },
      lastName: {
        label: "שם משפחה",
        placeholder: "מלאו שם משפחה",
      },
      identificationNumber: {
        label: "תעודת זהות",
        placeholder: "מלאו תעודת זהות",
      },
      phoneNumber: {
        label: "טלפון נייד",
        placeholder: "מלאו טלפון נייד",
      },
      success:{
        title:"סיימנו, תתחדשו",
        subTitle:"נתראה ביום הראשון ללימודים"
      }
    },
  },
};

export default translations;
