import React from "react";

import { ReactComponent as LockerimLogo } from "assets/images/blackLogo.svg";

import "./Logo.scss"

const Logo = () => {
  return <LockerimLogo className="logo" />;
};

export default Logo;
