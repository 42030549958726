import React, { useState } from "react";
import { Formik } from "formik";

import { useApi } from "hooks/useApi";
import useTranslations from "hooks/useTranslations";
import Input from "components/Input/Input";
import PageTitle from "components/PageTitle/PageTitle";
import Button from "components/Button/Button";
import LockersGif from "assets/images/lockers-gif.gif";
import { studentDetailsLinkValidation } from "utils/formValidation/studentDetailsLinkValidation";

import "./Migration.scss";
import { useLocation } from "react-router-dom";

const StudentDetailsLink = () => {
  const [showSuccessContent, setShowSuccessContent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { executeApi, post } = useApi();
  const { rcTranslate } = useTranslations();
  const location = useLocation();

  const realizationToken = location?.state?.realizationToken;
  const shovarNumber = location?.state?.shovarNumber;

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    identificationNumber: "",
  };

  const inputs = [
    {
      id: "firstName",
      type: "string",
      validation: "letters",
    },
    {
      id: "lastName",
      type: "string",
      validation: "letters",
    },
    {
      id: "phoneNumber",
      type: "tel",
      inputProps: { maxLength: 10 },
      validation: "number",
    },
    {
      id: "identificationNumber",
      type: "string",
      inputProps: { maxLength: 9 },
      validation: "number",
    },
  ];

  const handleSubmit = async (values, actions) => {
    try {
      const body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        identificationNumber: values?.identificationNumber,
        PhoneNumber: values?.phoneNumber,
        shovarNumber,
        realizationToken,
        // recaptchaKey: "1saf4sdg5dfh1", in future send recaptchaKey
      };
      const res = await executeApi(post("Shovar/RealizeShovar", body));
      if (res?.isSucceeded) {
        setShowSuccessContent(true);
      } else {
        let errorMessage = "",
          result;
        if (res?.message) {
          result = JSON.parse(res?.message);
        }
        if (result?.status && result?.status !== 400) {
          result?.data?.errors?.forEach((element) => {
            errorMessage += " " + element?.message;
          });
        } else {
          errorMessage = res;
        }
        setErrorMessage(errorMessage);
        setShowErrorMessage(true);
      }

      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  const studentDetailsContent = () => {
    return (
      <div className="studentDetails">
        <PageTitle
          title={rcTranslate("studentDetails.title")}
          className="title"
        />

        <div className="subTitle">
          <p>{rcTranslate("migration.studentDetails.subTitle")}</p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={studentDetailsLinkValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            setFieldError,
          }) => {
            const handleFieldTouch = (id) => {
              if (!touched[id]) {
                setFieldTouched(id, true);
              }
            };

            const onChange = (id, value) => {
              if (values[id] === value) {
                return;
              }
              handleFieldTouch(id);
              setFieldValue(id, value);
            };

            const getFieldError = (fieldId) =>
              errors[fieldId] && touched[fieldId] ? errors[fieldId] : null;

            const onSubmit = (e) => {
              e.preventDefault();
              handleSubmit();
            };

            return (
              <form className="studentDetails__form" onSubmit={onSubmit}>
                {inputs.map(({ id, type, validation, inputProps }) => (
                  <Input
                    key={id}
                    id={id}
                    type={type}
                    label={rcTranslate(`migration.studentDetails.${id}.label`)}
                    placeholder={rcTranslate(
                      `migration.studentDetails.${id}.placeholder`
                    )}
                    value={values[id] || ""}
                    onChange={(e) => onChange(id, e.target.value)}
                    onBlur={() => handleFieldTouch(id)}
                    error={getFieldError(id)}
                    validation={validation}
                    inputProps={inputProps}
                  />
                ))}
                {showErrorMessage && (
                  <p className="migration__errorMessage">{errorMessage}</p>
                )}
                <Button
                  type="submit"
                  title={rcTranslate("button.continue")}
                  disabled={
                    isSubmitting ||
                    Object.keys(errors)?.length > 0 ||
                    Object.values(values).find(
                      (i) => !i?.toString()?.length
                    ) !== undefined
                  }
                />
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  const successContent = () => {
    return (
      <div className="studentDetailsSuccess">
        <img
          className="migration__lockersGif"
          src={LockersGif}
          alt="lockers gif"
        />

        <PageTitle
          title={rcTranslate("migration.studentDetails.success.title")}
          className="title"
        />

        <div className="subTitle__success">
          <p>{rcTranslate("migration.studentDetails.success.subTitle")}</p>
        </div>
      </div>
    );
  };

  return showSuccessContent ? successContent() : studentDetailsContent();
};

export default StudentDetailsLink;
