const CONSTANTS = {
  STEPPER: {
    TOTAL_STEPS: {
      DEFAULT: 8,
      BY_GRADE: 8,
      BY_COMPOUND: 8,
      BY_LOCKER_NUMBER: 8,
      BY_STUDENT: 10, //For future usage
      SECOND_PHASE: 4
    },
    DEFAULT_CHECKED_STEP: ""
  }
};

export default CONSTANTS;
