import React, { useState } from "react";
import { Formik } from "formik";

import { useApi } from "hooks/useApi";
import useTranslations from "hooks/useTranslations";
import Input from "components/Input/Input";
import PageTitle from "components/PageTitle/PageTitle";
import Button from "components/Button/Button";
import { voucherValidation } from "utils/formValidation/voucherValidation";

import "./Migration.scss";
import { useHistory } from "react-router-dom";

const Voucher = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { executeApi, post } = useApi();
  const { rcTranslate } = useTranslations();
  const history = useHistory();

  const initialValues = {
    voucherNumber: "",
  };

  const inputs = [
    {
      id: "voucherNumber",
      type: "string",
      validation: "number",
    },
  ];

  const handleSubmit = async (values, actions) => {
    try {
      showErrorMessage && setShowErrorMessage(false);
      const body = {
        shovarNumber: values?.voucherNumber,
        // recaptchaKey: "1saf4sdg5dfh1",in future send recaptchaKey
      };
      const res = await await executeApi(post("Shovar/RedeemShovar", body));
      if (res?.isSucceeded) {
        history.push({
          pathname: "/otp",
          state: {
            shovarNumber: values?.voucherNumber,
            otpToken: res?.content?.otpToken,
          },
        });
      } else {
        let errorMessage = "",
          result;
        if (res?.message) {
          result = JSON.parse(res?.message);
        }
        if (result?.status && result?.status !== 400) {
          result?.data?.errors?.forEach((element) => {
            errorMessage += " " + element?.message;
          });
        } else {
          errorMessage = res;
        }
        setErrorMessage(errorMessage);
        setShowErrorMessage(true);
      }

      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className="studentDetails">
      <PageTitle
        title={rcTranslate("migration.voucher.title")}
        className="title"
      />

      <div className="subTitle">
        <p>{rcTranslate("migration.voucher.subTitle")}</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={voucherValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          setFieldError,
        }) => {
          const handleFieldTouch = (id) => {
            if (!touched[id]) {
              setFieldTouched(id, true);
            }
          };

          const onChange = (id, value) => {
            if (values.voucherNumber === value) {
              return;
            }

            handleFieldTouch(id);
            setFieldValue(id, value);
          };

          const getFieldError = (fieldId) =>
            errors[fieldId] && touched[fieldId] ? errors[fieldId] : null;

          const onSubmit = (e) => {
            e.preventDefault();
            handleSubmit();
          };

          return (
            <form className="studentDetails__form" onSubmit={onSubmit}>
              {inputs.map(({ id, type, validation, inputProps }) => (
                <Input
                  key={id}
                  id={id}
                  type={type}
                  label={rcTranslate(`migration.voucher.${id}.label`)}
                  placeholder={rcTranslate(
                    `migration.voucher.${id}.placeholder`
                  )}
                  value={values[id] || ""}
                  onChange={(e) => onChange(id, e.target.value)}
                  onBlur={() => handleFieldTouch(id)}
                  error={getFieldError(id)}
                  validation={validation}
                  inputProps={inputProps}
                />
              ))}

              {showErrorMessage && (
                <p className="migration__errorMessage">{errorMessage}</p>
              )}

              <Button
                type="submit"
                title={rcTranslate("button.continue")}
                disabled={
                  isSubmitting ||
                  Object.keys(errors)?.length > 0 ||
                  Object.values(values).find((i) => !i?.toString()?.length) !==
                    undefined
                }
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Voucher;
