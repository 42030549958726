import { object, array, boolean, string, number, mixed } from "yup";

import { checkIsraeliPhone, checkIsraeliID } from "utils/validation";

export const studentDetailsLinkValidation = object().shape({
  firstName: string().nullable().required("form.errors.required"),
  lastName: string().nullable().required("form.errors.required"),
  phoneNumber: string()
    .nullable()
    .test("check phone number", "form.errors.phoneNumber", (value) => {
      return checkIsraeliPhone(value);
    })
    .required("form.errors.required"),
  identificationNumber: string()
    .nullable()
    .test(
      "check identification number",
      "form.errors.identificationNumber",
      (value) => {
        return checkIsraeliID(value);
      }
    )
    .required("form.errors.required"),
});
