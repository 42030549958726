import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useStorage = () => {
  const [localStorageData, setLocalStorageData] = useState(
    localStorage.getItem("lockerim-onboarding")
      ? JSON.parse(localStorage.getItem("lockerim-onboarding"))
      : null
  );

  const initialData = useMemo(() => ({ appLanguage: "he" }), []);

  const routeHistory = useMemo(
    () => localStorageData?.routeHistory ?? [],
    [localStorageData?.routeHistory]
  );

  const location = useLocation();

  useEffect(() => {
    if (localStorageData === null) {
      localStorage.setItem("lockerim-onboarding", JSON.stringify(initialData));

      setLocalStorageData(initialData);
    }
  }, [initialData, localStorageData]);

  const saveToLocalStorage = useCallback(
    (data) => {
      const updatedData = { ...localStorageData, ...data };

      setLocalStorageData(updatedData);
      localStorage.setItem("lockerim-onboarding", JSON.stringify(updatedData));
      window.dispatchEvent(new Event("storage"));
    },
    [localStorageData]
  );

  const removeLocalStorageData = useCallback(() => {
    localStorage.setItem("lockerim-onboarding", JSON.stringify(initialData));

    setLocalStorageData(initialData);
    window.dispatchEvent(new Event("storage"));
  }, [initialData]);

  // Get values when localStorage is being updated
  useEffect(() => {
    let storageListener = () => {};

    storageListener = window.addEventListener("storage", (_event) => {
      let updatedLocalStorageData = localStorage.getItem("lockerim-onboarding");

      if (updatedLocalStorageData) {
        updatedLocalStorageData = JSON.parse(updatedLocalStorageData);

        setLocalStorageData(updatedLocalStorageData);
      }
    });

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  // Update route history and current step on every step mount
  useEffect(() => {
    if (
      location?.pathname === "/" ||
      location?.pathname === "/landing-page" ||
      routeHistory.includes(location?.pathname)
    )
      return;

    let updateTimeout;

    const newRouteHistory = [...routeHistory, location?.pathname];

    // We use timeout in order to save new values before navigating to the next page
    updateTimeout = setTimeout(() => {
      saveToLocalStorage({
        routeHistory: newRouteHistory
      });
    }, 150);

    return () => {
      clearTimeout(updateTimeout);
    };
  }, [location?.pathname, routeHistory, saveToLocalStorage]);

  return {
    localStorageData,
    saveToLocalStorage,
    removeLocalStorageData
  };
};
