import React from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";

import { useStorage } from "hooks/useStorage";
import Logo from "components/Logo/Logo";

import "./Header.scss";

const Header = ({ showGoBackButton = true }) => {
  const history = useHistory();
  const { removeLocalStorageData } = useStorage();

  const goToLandingPage = () => {
    removeLocalStorageData();
    history.push("/landing-page");
  };

  return ReactDOM.createPortal(
    <div
      className={showGoBackButton ? "header__wrapper" : "header__wrapper_logo"}
    >
      {showGoBackButton && (
        <button
          className="header__link"
          role={"link"}
          onClick={goToLandingPage}
        >
          חזרה לדף הראשי
        </button>
      )}

      <Logo />
    </div>,
    document.getElementById("header")
  );
};

export default Header;
